import { Component, OnInit } from '@angular/core';
import {User} from '../../../../models/user';
import {UserService} from '../../../../services/user.service';
import {ActivatedRoute, Router} from '@angular/router';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {Seller} from '../../../../models/seller';
import {NotificationService} from '../../../../services/notification.service ';

@Component({
  selector: 'app-user-login',
  templateUrl: './user-login.component.html',
  styleUrls: ['./user-login.component.css']
})
export class UserLoginComponent implements OnInit {

  user: User = new User();
  constructor(
    private userService: UserService,
    private router: Router,
    private ngxService: NgxUiLoaderService,
    private notifyService : NotificationService,
    private route: ActivatedRoute,
  ) { }

  ngOnInit(): void {
  }

  onSubmit(){
    this.ngxService.start();
      this.userService.getUserByEmailPassword(this.user.email, this.user.password).subscribe(
        value => {
          console.log(value);
          this.ngxService.stop();

         

          if(value.hasOwnProperty('id')){
            this.userService.addItem(JSON.stringify(value) );
            this.userService.user = value;
            this.userService.isAuth = true;
              this.router.navigateByUrl('/user');
            this.notifyService.showSuccess("Bienvenue!", "TELEFRET")
          }
          else {
            this.notifyService.showError("Email ou mot de passe incorrect!", "TELEFRET");
            return;
          }
        },
        error => {
          console.log(error);
          this.ngxService.stop();
          this.notifyService.showError("Email ou mot de passe incorrect!", "TELEFRET")
        }
      )
   
  }
}
