import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {UserComponent} from './views/front/user/user.component';
import {UserLoginComponent} from './views/front/user/user-login/user-login.component';
import {UserCandidateComponent} from './views/front/user/user-candidate/user-candidate.component';
import {AuthGuardService} from './services/auth-guard.service';
import {HomeComponent} from './views/front/home/home.component';
import {HomeNotFoundComponent} from './views/front/home/home-not-found/home-not-found.component';
import {HomeBuyComponent} from './views/front/home/home-buy/home-buy.component';
import {UserDetailsComponent} from './views/front/user/user-details/user-details.component';


const routes: Routes = [
  { path: '', component: UserComponent , },
  {path: 'login', component: UserLoginComponent},
  { path: ':id', component: UserComponent },

  {
    path: 'user',
    canActivate: [AuthGuardService],
    children:
      [
        { path: '', component: UserComponent },
        { path: 'details/:id', component: UserDetailsComponent },
        {path: 'candidate', component: UserCandidateComponent},
      ]
  },
  { path: 'not-found', component: HomeNotFoundComponent },
  { path: '**', redirectTo: 'not-found' },

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
