import {Component, OnInit} from '@angular/core';
import {UserService} from './services/user.service';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {LineOperation} from './models/line-operation';
import {Router} from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit{
  constructor(
              private router: Router,
              private userService: UserService) { }
              name = 'Angular';

              onAddressChange(event) {
                console.log('onAddressChange', event)
              }
  async ngOnInit(): Promise<void> {
    // this.ngxService.start()
    this.userService.checkIfUserExist();
    // this.ngxService.stop();

    // this.ngxService.start();
    // const seller: Seller = {
    //   Id: 1,
    //   CreateOn: new Date("2021-02-09T00:00:00"),
    //   UpdateOn: null,
    //   Email: "chaomaos@gmail.com",
    //   Password: "nÕƒ<óR†ëøf+{YIð×B»ì?",
    //   Status: null
    // }

    // this.sellerOperation.getOperationsOfSeller(1).subscribe(
    //   value => console.log(value),
    //   error => console.log(error)
    // )

  }
}
