<footer class="footer">
  <div class="copy-right_text wow fadeInUp" data-wow-duration="1.4s" data-wow-delay=".3s">
    <div class="container">
      <div class="footer_border"></div>
      <div class="row">
        <div class="col-xl-12">
          <p class="copy_right text-center">
            <br>
            Copyright &copy;
            <script data-cfasync="false" src="assets/js/cloudflare-static-email-decode.min.js"></script>
            <script>document.write(new Date().getFullYear());</script> All rights reserved | Scored  <i class="fa fa-heart-o" aria-hidden="true"></i> by <a href="#" target="_blank">2N</a>

          </p>
        </div>
      </div>
    </div>
  </div>
</footer>
