<app-home-header></app-home-header>

<div class="bradcam_area bradcam_bg_1">
  <div class="container">
    <div class="row">
      <div class="col-xl-12">
        <div class="bradcam_text">
          <h3>Connectez-vous</h3>
        </div>
      </div>
    </div>
  </div>
</div>

<section class="blog_area single-post-area">
  <div class="container">
    <div class="row">
      <div class="col-lg-8 posts-list">
        <div class="comment-form">
          <h4>Connectez-vous!!</h4>
          <form (ngSubmit)="onSubmit()" #f="ngForm" class="form-contact comment_form" id="commentForm">
            <div class="row">
              <div class="col-sm-12">
                <div class="form-group">
                  <input [(ngModel)]="user.email" class="form-control" name="email" id="email" type="email" placeholder="Email" required>
                </div>
              </div>
              <div class="col-12">
                <div class="form-group">
                  <input [(ngModel)]="user.password" class="form-control" name="password" id="password" type="password" placeholder="Mot de passe" required>
                </div>
              </div>
            </div>
            <div class="form-group">
              <button [disabled]="f.invalid" type="submit" class="button button-contactForm btn_1 boxed-btn3">Se connecter</button>
              <a style="text-decoration: underline" routerLink="/register" class="btn btn-link">Je n'ai pas encore de compte</a>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</section>

<app-home-footer></app-home-footer>
