import {Purchase} from './purchase';

export class User {
 


  "id": number;
  "idMainAccount": any;
  "username": "socopat";
  "password": any;
  "displayName": any;
  "email": string;
  "enabled": any;
  "lastPasswordResetDate": any;
  "createdAt": any;
  "token": string;
  "isRoot": boolean;
  "avatartUri": string;
  "expirationDate": Date;
  "authorities": [];
  "groups": [];
  "groupPointInterests": [];
  "rawPassword": any;
  "fcmservername": string;
  "vehicules": any;
  "options": any;
  "rapports": [];
  "accessLogs": any;
  "memory": number
}
