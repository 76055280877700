
<nav class="navbar navbar-expand-lg navbar-dark bg-primary fixed-top text-white">
  <div class="container-fluid">
    <div class="d-flex justify-content-between">
      <a  class="navbar-brand" routerLink="/">
        <img style="max-height: 160px; max-width: 20%" src="../../../../../assets/images/logo.png" alt="">
      </a>
      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
    </div>
    <div style="font-size: 14px" class="collapse navbar-collapse" id="navbarResponsive">
      <ul class="navbar-nav ml-2">
        <li class="nav-item">
          <a class="nav-link text-white" routerLink="/">Accueil</a>
        </li>
        <li class="nav-item">
          <a class="nav-link text-white" routerLink="/user">Trouver un packet</a>
        </li>
      
      </ul>
      <ul *ngIf="userService.user" class="navbar-nav ml-2">
        <li style="cursor: pointer" class="nav-item">
          <a class="nav-link text-white" (click)="logOut()">Log out</a>
        </li>
      </ul>
      <ul *ngIf="userService.user" class="navbar-nav">
        <li class="nav-item boxed-btn3"  style="padding:2px!important;">
          <a class="nav-link text-white" routerLink="/user">{{userService.user ? userService.user.username : ""}}  {{userService.user ? userService.user.email : "MON COMPTE"}}</a>
        </li>
      </ul>
    </div>
  </div>
</nav>
