import { Component, OnInit } from '@angular/core';
import {LineOperation} from '../../../../models/line-operation';
import {ActivatedRoute} from '@angular/router';
import {SellerOperation} from '../../../../models/seller-operation';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {NgDatnekCountrySelectService} from 'ng-datnek-select-country';
import {ConfirmationDialogService} from '../../../../services/confirmation-dialog.service';
import {NotificationService} from '../../../../services/notification.service ';

@Component({
  selector: 'app-user-details',
  templateUrl: './user-details.component.html',
  styleUrls: ['./user-details.component.css']
})
export class UserDetailsComponent implements OnInit {

  sellerOperation: SellerOperation;
  sellerOperationId: number;
  Taux=0;

  constructor(
 
  ) {
  }
  ngOnInit(): void {
    throw new Error('Method not implemented.');
  }


}
